export const DEFAULT_PROPERTY_ID = "DEFAULT_PROPERTY_ID";
export const DEFAULT_SCOPE_AWARE_IDS = "DEFAULT_SCOPE_AWARE_IDS";
export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";
export const PORTAL_TRIGGER = "PortalTrigger";
export const PORTAL_TRIGGER_C2E = "PortalTriggerC2E";
export const SET_ENTITY_CONTEXT = "SetEntityContext";
export const JOIN_GROUP = "JoinGroup";
export const DEVICE_STREAM_TOPIC = "DEVICE_STREAM";
export const EVENT_TOPIC = "EVENT";
export const DSD_LITE_TOPIC = "DSD_LITE";
export const DEVICE_CONNECTED_TOPIC = "DEVICE_CONNECTED";
export const DEVICE_DISCONNECTED_TOPIC = "DEVICE_DISCONNECTED";
export const DEVICE_STATE_READ_TOPIC = "DEVICE_STATE_READ";
export const MONEY_INVENTORY_TOPIC = "MONEY_INVENTORY";
export const NON_RESET_COUNTS_TOPIC = "NON_RESET_COUNTS";
export const ACTIVITY_CHANGE_TOPIC = "ACTIVITY_CHANGE";
export const NON_RESET_COUNTS_REQUEST_TOPIC = "NON_RESET_COUNTS_REQUEST_TOPIC";
export const MONEY_INVENTORY_REQUEST_TOPIC = "MONEY_INVENTORY_REQUEST";
export const CONFIGURE_DENOMINATION_ACCEPTED_TOPIC =
  "CONFIGURE_DENOMINATION_ACCEPTED";
export const ARMING_LOOP_STATUS_TOPIC = "ARMING_LOOP_STATUS";
export const GATE_TOPIC = "GATE";
export const RATE_CHANGE_TOPIC = "RATE_CHANGE";
export const GPIO_TOPIC = "GPIO";
export const GPIO_STATUS_CHANGE_TOPIC = "GPIO_STATUS_CHANGE";
export const CONNECTION_STATUS_CHANGE_TOPIC = "CONNECTION_STATUS_CHANGE";
export const FULL_SIGN = "FULL_SIGN";
export const CALLCENTER_REQUEST = "CALLCENTER";
export const DEVICE_ANSWER_CALL = "DEVICE_ANSWER_CALL";
export const ALERT_CHANGE = "ALERT_CHANGE";
export const PAYMENT_TENDERED = "PAYMENT_TENDERED";
export const FORWARD_MESSAGE_BODY_ONLY = "FORWARD_MESSAGE_BODY_ONLY";
export const OCCUPANCY_DEMAND_CHANGE = "OCCUPANCY_DEMAND_CHANGE";
export const OCCUPANCY_DEMAND = "OCCUPANCY_DEMAND";
export const REVENUE_DASHBOARD_DATA_CHANGE = "REVENUE_DASHBOARD_DATA_CHANGE";
export const ACCESS_GROUP_COUNT_CHANGE = "ACCESS_GROUP_COUNT_CHANGE";
export const CREDENTIAL_UPDATED = "CREDENTIAL_UPDATED";
export const REVENUE_WIDGET = "REVENUE_WIDGET";
export const CALL_CENTER_CAPTURE_UPLOADED = "CALL_CENTER_CAPTURE_UPLOADED";
export const CARD_ON_FILE_STATE_CHANGE = "CARD_ON_FILE_STATE_CHANGE";

export const ALL_TOPICS = [
  DEFAULT_PROPERTY_ID,
  DEFAULT_SCOPE_AWARE_IDS,
  EMPTY_GUID,
  DEVICE_STATE_READ_TOPIC,
  MONEY_INVENTORY_TOPIC,
  NON_RESET_COUNTS_TOPIC,
  ACTIVITY_CHANGE_TOPIC,
  NON_RESET_COUNTS_REQUEST_TOPIC,
  MONEY_INVENTORY_REQUEST_TOPIC,
  CONFIGURE_DENOMINATION_ACCEPTED_TOPIC,
  ARMING_LOOP_STATUS_TOPIC,
  GATE_TOPIC,
  GPIO_TOPIC,
  GPIO_STATUS_CHANGE_TOPIC,
  CONNECTION_STATUS_CHANGE_TOPIC,
  CALLCENTER_REQUEST,
  ALERT_CHANGE,
  OCCUPANCY_DEMAND_CHANGE,
  OCCUPANCY_DEMAND,
  REVENUE_DASHBOARD_DATA_CHANGE,
  ACCESS_GROUP_COUNT_CHANGE,
  REVENUE_WIDGET,
  CALL_CENTER_CAPTURE_UPLOADED,
  CARD_ON_FILE_STATE_CHANGE
];


//this should stay in sync with the a1-cloud package AMI.AmanoOne.Cloud.Auditing AuditType enum
export const AUDIT_TYPES = {
  1: "User",
  2: "Report",
  3: "Rate",
  4: "Access Holder",
  5: "Validation",
  6: "Validation Account",
  7: "Occupancy",
  8: "User Group",
  9: "Access Group",
  10: "Third Party",
  11: "Entities",
  12: "Call Center",
  13: "Credentials",
  14: "AMI",
  15: "Cashier Shift",
  16: "Contracts",
  17: "Alert",
  18: "Import History",
  19: "License Plate",
  20: "Gate",
};

//this should stay in sync with the a1-cloud package AMI.AmanoOne.Cloud.Auditing SubAuditType enum
export const AUDIT_SUBTYPES = {
  1: "Edit",
  2: "Add",
  3: "Get",
  4: "Delete",
  5: "Login",
  6: "Logout",
  7: "Forgot Password",
  8: "Context Switch In",
  9: "Context Switch Out",
  10: "Status Change",
  11: "Status Sync",
  12: "Mode Change",
  13: "Unclaim",
  14: "Bulk Download",
  15: "Bulk Claim",
  16: "Claim",
  17: "Count Increment",
  18: "Settings Change",
  19: "Provisioning",
  20: "BNR Inventory",
  21: "BNR Configuration",
  22: "Ticket Requested",
  23: "Video Connected",
  24: "Screen Captured",
  25: "Activity",
  26: "Video Disconnected",
  27: "Ticket Completed",
  28: "Ticket Viewed",
  29: "Clear",
  30: "Import History",
  31: "Open Gate",
  32: "Close Gate",
  33: "Cycle Gate",
};

export const CALLCENTER_ACTIONS = {
  TICKET_GENERATED: 1,
  OVERRIDE_CREDENTIAL: 2,
  GATE_OPENED: 3,
  GATE_VEND: 4,
  GATE_CLOSED: 5,
  NEGOTIATE_TICKET: 6,
  ISSUE_VALIDATION: 7,
  OVERRIDE_NESTVIOLATION: 8,
};

export const Hub = {
  PORTAL: "PortalTrigger",
  CALLCENTER: "CallCenter",
  BROADCAST: "Broadcast",
};

export const CONNECTION_STATE = {
  DISCONNECTED: 0,
  CONNECTING: 1,
  CONNECTED: 2,
};

export const OPERATION_STATUS = {
  Unset: 0,
  Offline: 1,
  Online: 2,
  Disabled: 3,
};
export const LOOP_STATUS = {
  Unset: 0,
  Active: 1,
  Inactive: 2,
};

export const GPIO_RESULT_STATE = {
  ConfigurationFault: -8,
  MachineIDFault: -7,
  BoardReadOutputFault: -6,
  BoardReadInputFault: -5,
  BoardWriteOutputFault: -4,
  BoardNotOpenFault: -3,
  RetrieveConfigurationFault: -2,
  IOFault: -1,
  Unset: 0,
  TurnedOff: 1,
  TurnedOn: 2,
  Triggered: 3,
};

export const GPIO_STATE = {
  Off: 0,
  On: 1,
  Trigger: 2,
  Read: 3,
};

export const ALERT_SEVERITY = {
  Unset: 1,
  Low: 2,
  Medium: 3,
  High: 4,
  Critical: 5,
};

export const DEVICE_MODE = {
  ENTRY: "Entry",
  EXIT: "Exit",
  NEUTRAL: "Neutral",
  KIOSK: "Kiosk",
  DIAGNOSTICS: "Diagnostic",
  DOOR: "Door",
  HOTEL_INTERFACE: "Hotel Interface",
  UNKNOWN: "Unknown",
};

export const ENTITY_TYPE = {
  Tenant: 1,
  Facility: 2,
  Device: 3,
  AccessGroup: 4,
  Area: 5,
  Lane: 7,
  UserGroup: 8,
  ContractHolder: 9,
  FacilityGroup: 10,
  ValetArea: 11,
};
export const ERROR_TYPES = {
  Device: "Device",
  Printer: "Printer",
  BNR: "BNR",
  CCReader: "CC",
  Gate: "GATE",
  ArmingLoop: "ArmingLoop",
  ClosingLoop: "ClosingLoop",
  Rate: "Rate",
};
export const SEVERITY = {
  LOW: 0,
  INFO: 20,
  WARNING: 50,
  CRITICAL: 80,
  WORLD_IS_ON_FIRE: 99
};

export const TRANSIENT_TYPES = ["prepaid", "ticket"];

export const USE_ACTIVITY_DATE_AS_EXIT_DATE = ["ticket"];

export const OCCUPANCY_TYPES = {
  Transient: "Transient",
  Contract: "Contract",
  Facility: "Facility",
};

export const CREDENTIAL_TYPES = {
  NA: 0,
  TICKET: 1,
  CC: 2,
  TOLLTAG: 3,
  LICENSEPLATE: 4,
  RFID: 5,
  BARCODE: 6,
  PROX: 7,
  AVI: 8,
  MAGSTRIPE: 9,
  PREPAID: 10,
  UNKNOWN: 11,
  TICKETMASTER: 13,
};

export const CREDENTIAL_TYPE_STRING_VALUE = {
  LICENSEPLATE: "license plate",
};

export const ACTIVITY_TYPES = {
  Enter: "Enter",
  Exit: "Exit",
  Payment: "Payment",
  LostTicket: "Lost ticket",
  PayOnEntry: "Pay On Entry",
};

export const TICKET_STATUSES = {
  Enter: "enter",
  Exit: "exit",
  Payment: "payment",
  Void: "void",
};

export const BACKOUT_ACTIVITY_TYPES = [
  "Backout without ticket",
  "Backout with ticket",
];

export const VALID_ACCESS = "Valid Access";
export const SOFT_ANTIPASSBACK = "Soft Anti-Passback";

export const PERIPHERAL_TYPES = {
  GATE: {
    id: 1,
    friendlyName: "Gate",
    validModes: [
      DEVICE_MODE.ENTRY,
      DEVICE_MODE.EXIT,
      DEVICE_MODE.DIAGNOSTICS,
      DEVICE_MODE.NEUTRAL,
    ],
  },
  PRINTER: {
    id: 2,
    friendlyName: "Printer",
    validModes: [
      DEVICE_MODE.ENTRY,
      DEVICE_MODE.EXIT,
      DEVICE_MODE.KIOSK,
      DEVICE_MODE.DIAGNOSTICS,
      DEVICE_MODE.NEUTRAL,
    ],
  },
  SCANNER: {
    id: 3,
    friendlyName: "Scanner",
    validModes: [
      DEVICE_MODE.ENTRY,
      DEVICE_MODE.EXIT,
      DEVICE_MODE.KIOSK,
      DEVICE_MODE.DIAGNOSTICS,
      DEVICE_MODE.NEUTRAL,
      DEVICE_MODE.DOOR,
    ],
  },
  BNR: {
    id: 5,
    friendlyName: "BNR",
    validModes: [
      DEVICE_MODE.EXIT,
      DEVICE_MODE.KIOSK,
      DEVICE_MODE.DIAGNOSTICS,
      DEVICE_MODE.NEUTRAL,
    ],
  },
  PROXCARDREADER: {
    id: 6,
    friendlyName: "Prox Card Reader",
    validModes: [
      DEVICE_MODE.ENTRY,
      DEVICE_MODE.EXIT,
      DEVICE_MODE.DIAGNOSTICS,
      DEVICE_MODE.NEUTRAL,
      DEVICE_MODE.DOOR,
    ],
  },
  CREDITCARDREADER: {
    id: 7,
    friendlyName: "Credit Card Terminal",
    validModes: [
      DEVICE_MODE.ENTRY,
      DEVICE_MODE.EXIT,
      DEVICE_MODE.KIOSK,
      DEVICE_MODE.DIAGNOSTICS,
      DEVICE_MODE.NEUTRAL,
    ],
  },
  DOORSTRIKE: {
    id: 8,
    friendlyName: "Door Strike",
    validModes: [DEVICE_MODE.DOOR],
  },
};

export const USER_TYPES = {
  Admin: 0,
  ValidationAccount: 1,
};

export const PAYMENTS_TENDERED_ENTRY_TYPES = {
  CashApplied: 1,
  CreditCardApplied: 2,
  ValidationDiscount: 3,
  ValidationSurchage: 4,
  BaseFee: 5,
  OverageFee: 6,
  LateFee: 7,
  RepayFee: 8,
  CashBack: 9,
  ConvenienceFee: 10,
  Tax: 11,
  FlexParkingFee: 12,
};

export const PAYMENT_TYPES = {
  CreditCard: 1,
  DebitCard: 2,
  Cash: 3,
  Check: 4,
  PayPal: 5,
  NFCPayment: 6,
  TollTag: 7,
  Validation: 8,
};

export const USER_ERROR_TYPES = {
  EmailExists: 1,
};

export const SYSTEM_EVENTS = {
  DOOR_OPENED_ILLEGALLY: 1,
  USER_OPENED_DOOR: 2,
  BNR_BANK_NOTE_JAM: 3,
  BILL_VAULT_FULL: 4,
  USER_ADDED_BANK_NOTES: 5,
  BNR_OUT_OF_DENOMINATION: 6,
  DOOR_CLOSED: 7,
  BNR_ERROR: 8,
  RECEIPT_JAM: 9,
  RECEIPT_PAPER_LOW: 10,
  RECEIPT_PAPER_OUT: 11,
  DEVICE_POWER_CYCLE: 12,
  BACKOUT_WITH_TICKET: 13,
  BACKOUT_WITHOUT_TICKET: 14,
  DEVICE_RECONNECT: 15,
  FULL_SIGN: 16,
  MONEY_INVENTORY: 17,
  DEVICE_CLOSED_MANUALLY: 18,
  CABINET_DOOR: 19,
  LANE_CLOSED_SIGN: 20,
  INVENTORY_REQUEST: 21,
  CASHBOX_REMOVED: 22,
  CASH_LOADED: 23,
  CASH_DEVICE_ERROR: 24,
  TOUCHLESS_BUTTON: 25,
  CALL_CENTER_STARTED: 26,
  CALL_CENTER_COMPLETED: 27,
  CALL_CENTER_CANCELLED: 28,
};

export const TEXT_ACTION_EVENTS = {
  CALL_CENTER_STARTED: SYSTEM_EVENTS.CALL_CENTER_STARTED,
  CALL_CENTER_COMPLETED: SYSTEM_EVENTS.CALL_CENTER_COMPLETED,
  CALL_CENTER_CANCELLED: SYSTEM_EVENTS.CALL_CENTER_CANCELLED,
};

export const ALERT_TYPES = {
  DOOR_OPENED_ILLEGALLY: 1,
  USER_OPENED_DOOR: 2,
  BNR_BANK_NOTE_JAM: 3,
  BILL_VAULT_FULL: 4,
  USER_ADDED_BANK_NOTES: 5,
  BNR_OUT_OF_DENOMINATION: 6,
  DOOR_CLOSED: 7,
  BNR_ERROR: 8,
  RECEIPT_JAM: 9,
  RECEIPT_PAPER_LOW: 10,
  RECEIPT_PAPER_OUT: 11,
  DEVICE_POWER_CYCLE: 12,
  BACKOUT_WITH_TICKET: 13,
  BACKOUT_WITHOUT_TICKET: 14,
  DEVICE_RECONNECT: 15,
  FULL_SIGN: 16,
  MONEY_INVENTORY: 17,
  CABINET_DOOR: 19,
  LANE_CLOSED_SIGN: 20,
  INVENTORY_REQUEST: 21,
  CASHBOX_REMOVED: 22,
  CASH_LOADED: 23,
  CASH_DEVICE_ERROR: 24,
  TOUCHLESS_BUTTON: 25,
};

export const PARKING_ACTIVITY_STATUS = {
  Enter: 1,
  Exit: 2,
  BackoutWithTicket: 3,
  Cancel: 4,
  Void: 5,
  Payment: 6,
  Active: 7,
  MobilePayment: 8,
  ManualIn: 9,
  ManualOut: 10,
  BackoutWithoutTicket: 11,
  LostTicket: 12,
};

export const REASONS_FOR_PAYMENT = {
  ForLostTicket: 1,
  WasCanceled: 2,
  ForMobilePay: 3,
};

export const VALIDATION_TYPES_ENUM = {
  Unset: 0,
  Percentage: 1,
  Amount: 2,
  "Flat Fee": 3,
  "Rate Switch": 4,
  "Adjust Entry Time": 5,
  "Adjust Exit Time": 6,
};

export const VALIDATION_STATUSES_ENUM = {
  "Not Used": 0,
  Used: 1,
  "To Be Used": 2,
};

export const VALIDATION_KINDS_ENUM = {
  Unset: 0,
  Online: 1,
  Manual: 2,
  Physical: 3,
};

export const VALIDATION_FORMATS_ENUM = {
  Unset: 0,
  "Code 39": 1,
  "Code 128": 2,
  "QR Code": 3,
  "Data Matrix": 4,
  "Interleaved 2 Of 5": 5,
  UPC: 6,
  EAN: 7,
  "PDF 417": 8,
};

export const GetStringToEnumValue = (enums, str) => {
  if (str) {
    for (let item in enums) {
      if (item.toLowerCase() === str.toLowerCase()) {
        return enums[item];
      }
    }
  }
  return null;
};

export const ACCEPTED_CREDIT_CARDS = {
  Unset: 0,
  VISA: 1,
  MASTERCARD: 2,
  DISCOVER: 3,
  AMEX: 4,
  Other: 5,
};

export const GPIO_BOARD_TYPES = {
  Unset: 0,
  Sealevel: 1,
  AMI: 2,
  "Direct GPIO": 3,
};

export const GPIO_TYPE = {
  Unset: 0,
  Output: 1,
  Input: 2,
};

export const GPIO_COUNT_TYPE = {
  Unset: 0,
  Facility: 1,
  Contract: 2,
  Transient: 3,
};

export const ROUTINE_TYPE = {
  Alert: 1,
  Email: 2,
  SMS: 3,
  OCCUPANCY_COUNT: 4,
  NON_RESET_COUNT: 5,
  ReverseLane: 6,
  Inventory: 7,
};

export const ACCESS_GROUP_TYPE = {
  Normal: 1,
  "Flexible Parking Account": 2,
  "Shared Accounts": 3,
  "Valet Pairing": 4,
  "Issue Ticket": 5,
  "Day Use": 6
};

export const FLEXIBLE_PARKING_ACCOUNT_FREQUENCY = {
  Unlimited: 1,
  ChargeOncePerDay: 2,
  ChargeOncePer24Hours: 3,
  ChargeUntilDailyMax: 4,
  ChargeUntil24HourMax: 5,
};

export const FUND_ADJUSTMENT_TYPE = {
  Payment: 1,
  Charge: 2,
  Void: 3,
};

export const CONTRACT_DETAILS_TYPE = {
  "Flexible Parking Account": 1,
};

export const FUND_TYPE = {
  Amount: 1,
  "By Use": 2,
};

export const ANTI_PASSBACK_TYPE = {
  Hard: "HardAP",
  Soft: "SoftAP",
  None: "None",
};

export const NEST_VIOLATION_TYPE = {
  Hard: "Hard",
  Soft: "Soft",
};

export const POE_PRICING_TYPE = {
  Unset: "Unset",
  Flatfee: "Flat Fee",
  Userate: "Use Rate",
};

export const GATE_TYPE = {
  "1200": 1,
  GPIO: 2,
};

export const INTERCOM_PROVIDER = {
  External: 0,
  Amano: 1,
  Parker: 2,
};

export const COUNTRY_CODES = {
  UnitedStates: 1,
  Canada: 42,
};

export const REPAYMENT_MODE = {
  Restart: 0,
  Continued: 1,
};

export const INTERFACE_BOARD_TYPE = {
  "Overture board": 1,
  "Amano ONE board": 2,
};

export const VEHICLE_TYPE = {
  Gasoline: 1,
  Electric: 2,
  Hybrid: 3
};

export const REVERSE_LANE_MODE = {
  Unknown: 0,
  Entry: 1,
  Exit: 2,
};

export const PAYMENT_GATEWAY = {
  WINDCAVE: 1,
  PLANET: 2
};

export const CARD_TRANSACTION_STATE = {
  PENDING: 1,
  SUCCESS: 2,
  FAIL: 3
}

export const OFFLINE_TICKET_EXIT_FUNCTION = {
  DisplayOfflineMessage: "Display Offline Message",
  FreeExitWhenTicketPresented: "Free Exit When Ticket is Presented",
};

export const OFFLINE_TICKET_EXIT_FUNCTION_VALUES = {
  DisplayOfflineMessage: 1,
  FreeExitWhenTicketPresented: 2,
};

export const CASHIER_DEVICE = {
  RoamingCashierdefault: "-1",
  ValetHandheldCashier: "-2",
};

export const TICKET_FIELD_REQUEST_TYPE = {
  ARRIVAL: 1,
  REQUEST: 2,
};

export const LPR_ENTRY_CAPTURE_MODE = {
  BeforeEntry: 1,
  AfterEntry: 2
};

export const TICKET_SEARCH_TYPE = {
  TICKET: 0,
  LICENSE_PLATE: 1
};
export const CALLCENTER_RESULT_CODE = {
  NOT_FOUND: 5
};

export const FPA_PAYMENT_METHODS = {
  CHARGE_FPA: 0,
  CHARGE_CCOF: 1
}